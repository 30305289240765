import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ContentPackageWrapper } from '../../responsive/organisms/ContentPackageWrapper';
import {
  mapContentToCardData,
  removeAuthorsAndDateFromCardData,
  removeTaxonomyElementsFromCardData
} from '../../../domain/utils/contentUtils';
import { CardItemHorizontalLegacy } from '../../responsive/organisms/CardItemHorizontalLegacy';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { Container } from '../../responsive/atoms/Container';
import { stripTags } from '../../../domain/utils/stringUtils';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';

const OneUpInner = ({ data, isAboveTheFold, darkBackgroundMode }) => {
  const cards = data.cards.map(card => {
    const cardEntity = card?.entity?.oneUpRefCard?.entity;
    if (!cardEntity) {
      return null;
    }
    const cardData = removeTaxonomyElementsFromCardData(
      removeAuthorsAndDateFromCardData(mapContentToCardData(cardEntity))
    );
    cardData.subtitle = stripTags(
      cardEntity.promoSummary?.value || cardEntity.subHeadline
    );
    cardData.imageAlign = card.entity.imageAlign;
    cardData.title = card.entity.titleOverride || cardData.title;
    cardData.subtitle = card.entity.subHeadLineOverride || cardData.subtitle;
    cardData.url = card.entity.link?.uri || cardData.url;
    if (card.entity.openInANewTab) {
      cardData.target = '_blank';
    }
    delete cardData.taxonomies;
    return cardData;
  });

  const fontColor = data.backgroundColor === 'white' ? 'black' : 'white';

  return (
    <ContentPackageWrapper
      contentPackageHeader={data.contentPackageHeader}
      contentPackageDescription={data.contentPackageDescription}
      backgroundColor={darkBackgroundMode ? 'true_black' : data.backgroundColor}
      headerStyles={css`
        color: #000;
        ${theme.font.family('boldWeb')};
        font-size: ${theme.font.size('xl2')};
        margin-bottom: ${theme.spacing('xl')};
        &::after {
          content: '';
          display: block;
          width: 60px;
          height: 3px;
          background: #fc0;
          margin: 10px auto;
        }
      `}
      descriptionStyles={css`
        color: #000;
      `}
      extraStyles={css`
        contain-intrinsic-size: 0 1000px;
        padding-top: ${theme.spacing(
          data.contentPackageHeader ? 'base' : 'xl3'
        )};
        padding-bottom: ${theme.spacing('xl3')};
        margin-bottom: 0;
      `}
    >
      <Container maxWidth="70rem" padding={[null, '0 $l']}>
        <GridRow gutter={[null, null, null, 'l']}>
          <GridColumn width={12} childrenMarginBottom="xl2">
            {cards.map(cardData => (
              <CardItemHorizontalLegacy
                imageMaxWidths={{
                  tablet: 660
                }}
                imageColumns={[12, 8, null, null]}
                imageAlign={cardData.imageAlign}
                textColumns={[12, 4, null, null]}
                data={cardData}
                fontColor={fontColor}
                isAboveTheFold={isAboveTheFold}
                itemsAlign="center"
                key={cardData.url}
              />
            ))}
          </GridColumn>
        </GridRow>
      </Container>
    </ContentPackageWrapper>
  );
};
OneUpInner.defaultProps = {
  isAboveTheFold: false,
  darkBackgroundMode: false
};

OneUpInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  darkBackgroundMode: PropTypes.bool,
  isAboveTheFold: PropTypes.bool
};

export const OneUp = ParagraphWrapper(OneUpInner);
