import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { CardItemLegacy } from './CardItemLegacy';

export const CardItemHorizontalLegacy = ({
  data,
  imageMaxWidths,
  imageAlign,
  imageColumns,
  textColumns,
  fontColor,
  itemsAlign,
  gtmClickEvent,
  isAboveTheFold,
  styles
}) => {
  const style = {
    container: css`
      width: 100%;
      ${theme.mq.tablet} {
        display: flex;
        align-items: ${itemsAlign};
        flex-direction: ${imageAlign === 'right' ? 'row-reverse' : 'row'};
      }
    `,

    imageContainer: css`
      position: relative;
      ${facePaint({
        width: imageColumns.map(columnSize => {
          if (columnSize === null) return null;
          if (columnSize === 12) return theme.grid.columns(12);
          return `calc( ${theme.grid.columns(columnSize)} - ${theme.spacing(
            'base'
          )} )`;
        }),
        [`margin-${imageAlign === 'left' ? 'right' : 'left'}`]: [
          null,
          theme.spacing('base')
        ]
      })};
    `,

    textContainer: css`
      ${facePaint({
        width: textColumns.map(columnSize => {
          if (columnSize === null) return null;
          return theme.grid.columns(columnSize);
        })
      })};
      padding: 0 56px 0 30px;
      ${theme.mq.small_desktop} {
        padding: 0;
      }
      color: ${fontColor};
      a {
        color: ${fontColor};
      }
    `
  };

  return (
    <CardItemLegacy
      data={data}
      imageMaxWidths={imageMaxWidths}
      imageAlign={imageAlign}
      containerStyles={styles?.container || style.container}
      imageContainerStyles={
        styles?.image?.imageContainer || style.imageContainer
      }
      textContainerStyles={styles?.text?.textContainer || style.textContainer}
      gtmClickEvent={gtmClickEvent}
      isAboveTheFold={isAboveTheFold}
      styles={styles}
    />
  );
};
CardItemHorizontalLegacy.defaultProps = {
  gtmClickEvent: undefined,
  imageMaxWidths: undefined,
  imageAlign: 'left',
  imageColumns: [12, 5, null, 4],
  textColumns: [12, 7, null, 8],
  itemsAlign: 'flex-start',
  fontColor: 'black',
  isAboveTheFold: false,
  styles: undefined
};

CardItemHorizontalLegacy.propTypes = {
  data: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
  imageMaxWidths: PropTypes.objectOf(PropTypes.any),
  imageAlign: PropTypes.oneOf(['left', 'right']),
  imageColumns: PropTypes.arrayOf(PropTypes.number),
  textColumns: PropTypes.arrayOf(PropTypes.number),
  fontColor: PropTypes.string,
  itemsAlign: PropTypes.oneOf(['flex-start', 'center']),
  gtmClickEvent: PropTypes.objectOf(PropTypes.any),
  isAboveTheFold: PropTypes.bool,
  styles: PropTypes.shape({
    container: PropTypes.objectOf(PropTypes.any),
    image: PropTypes.objectOf(PropTypes.any),
    text: PropTypes.objectOf(PropTypes.any)
  })
};
